<template>
    <div>
        <div class="img-home" id="img-convergence">
            <div>
                <v-row >
                    <v-col cols="12" sm="9" class="text-home">
                        <h1 class="big-title"> La solution de suivi pour les espaces collectifs et les groupes</h1>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-row>
            <v-col cols="12" sm="1" md="1" lg="1"></v-col>
            <v-col cols="12" sm="10" md="4" lg="4" class="mt50" >
                <div id="le-biotagsensor" class="trais"></div>
                <h1 class="green-title">La Solution Convergence</h1>
                <p class="text-classi">Le boitier Convergence, associé à un réseau local (internet, WIFI, 3G 4G 5G  …), connecte plusieurs implants BioTagSensor au Biotag Cloud. <br>
                    La Convergence est la solution idéale pour connecter un groupe de personnes implantées et situées physiquement à proximité comme des patients d’un hôpital, un ou plusieurs élèves d’une classe, une maison de retraite, des marins sur un bateau, des militaires en opération, une équipe pratiquant un sport (…). <br><br>
                    Le système Convergence est très facile à installer et utilisable par tous. Le système est aussi l’outil parfait pour alerter une personne responsable d’un groupe si un ou plusieurs évènements médicaux sont détectés dans ce groupe.
                </p>
               <div class="btn-loc">
                    <router-link to="/contact" id="btn" >Contactez-nous</router-link>
                </div>
            </v-col>
            <v-col sm="1" md="1" lg="2"></v-col>
            <v-col cols="12" sm="10" md="4" lg="4">
                <div class="img-convergence">
                    <img src="../images/boitierconvergence3.png" alt="cage équipée">
                </div>
            </v-col>
        </v-row>
        <v-row>
           <v-col cols="12" sm="0" lg="1"></v-col>
             <v-col cols="12" sm="12" lg="5" class="row-between mt100">
                <div class="div-img-boitier">
                     <img src="../images/CouloirHôpital.jpg" alt="cage équipée">
                </div>
              </v-col>
              <v-col cols="12" sm="0" lg="1"></v-col>
              <v-col cols="12" sm="12" lg="4" class="row-between mt100">
                <div class="avantages-perf">
                    <h3>avantages</h3>
                    <div style="display: flex; justify-content: center;">
                        <div class="trais-v"></div>
                    </div>
                    <div class="text" style="margin-top: 35px;">
                        <div>
                            <img src="../assets/ico/flèche.png" alt="" style="width: 5%">
                            <h4>Une meilleure surveillance de la santé en temps réel</h4>
                        </div>
                    </div>
                    <div class="text">
                        <ul style="text-align: left; width: 85%;">
                            <li style="list-style-type: none;">Il est possible de surveiller en temps réel l'état de santé de plusieurs personnes à la fois.</li>
                        </ul>
                    </div>
                    <div class="text">
                        <div>
                            <img src="../assets/ico/flèche.png" alt="" style="width: 5%">
                            <h4>Une meilleure gestion des données</h4>
                        </div>
                    </div>
                    <div class="text">
                        <ul style="text-align: left; width: 85%;">
                            <li style="list-style-type: none;" >Avec un lecteur central qui collecte les données de plusieurs implants, il est plus facile de gérer les données collectées et de les utiliser pour une analyse globale.</li>
                        </ul>
                    </div>
                    <div class="text">
                        <div>
                            <img src="../assets/ico/flèche.png" alt="" style="width: 5%">
                            <h4>Une plus grande flexibilité</h4>
                        </div>
                    </div>
                    <div class="text">
                        <ul style="text-align: left; width: 85%;">
                            <li style="list-style-type: none;" >La possibilité de connecter plusieurs implants à un seul lecteur central permet de s'adapter à différents environnements et de répondre à des besoins spécifiques.</li>
                        </ul>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="1" lg="1"></v-col>
             <v-col cols="12" sm="10" lg="10">
                <h1 class="green-title mt50"> Comment ça marche ?</h1>
                <p class="text-classi">Le boîtier Convergence, autonome et connecté, transmet toutes les données d’un implant ou les données de tout groupe situé à proximité du boîtier. Les données sont ensuite visualisables sur BioTag Cloud</p>
            </v-col>
        
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="1" lg="1"></v-col>
             <v-col cols="12" sm="10" lg="10" class="row-center" style="margin-top: -60px;">
                <img src="../assets/schema/schema_convergence.png" alt="" style="width: 70%;">
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="10" lg="10">
                <div class="trais-vr"></div>
                <h1 style="width: 70%;" class="green-title">Exemple d’applications</h1>
                <div class="applications">
                    <div>
                        <img src="../images/retraités_small.jpg" alt="medecin derrière son ordi">
                        <h3>Suivi des pensionnaires dans une maison de retraite</h3>
                        <p class="text-classi">Les données de santé des pensionnaires sont envoyées au Boîtier Convergence qui les transmet ensuite au Biotag Cloud. Le Boîtier Convergence émet également des alertes ciblées à un ou plusieurs bureaux pour prévenir le personnel de santé de tout potentiel problème concernant un ou plusieurs pensionnaires.</p>
                    </div>
                    <div>
                        <img src="../images/ecoliers.png" alt="application ciblée d'un pansement">
                        <h3>Suivi des élèves en classe</h3>
                        <p>Les données de santé des élèves sont collectées par le Boîtier Convergence, qui les transmet ensuite au Biotag Cloud. Le directeur de l'école, les enseignants ou l'infirmière sont ensuite alertés par le Boîtier Convergence et peuvent prendre les mesures nécessaires en conséquence.</p>
                    </div>
                     <div>
                        <img src="../images/sous-marin.jpg" alt="medecin derrière son ordi">
                        <h3>Suivi des militaires en milieu clos</h3>
                        <p>Le boîtier Convergence permet le suivi médical en temps réel des militaires en mission en milieu clos, avec des alertes ciblées en cas de potentiel problème de santé.
                            Les personnels de santé peuvent ainsi intervenir rapidement pour éviter des complications et assurer la sécurité et la santé des militaires en mission.</p>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="1" lg="1"></v-col>
            <v-col cols="12" sm="10" lg="10">
                <div class="applications">
                  <div>
                        <img src="../images/lits.jpg" alt="travail">
                        <h3>Suivi collectif de la santé dans les salles d'hôpital</h3>
                        <p>Les données santé des patients arrivent toutes au Boîtier Convergence qui les transmets au Biotag Cloud.
                            Le médecin et les personnels de santé ont alors accès en temps réel aux informations santé de leurs patients via l’application BioTagSensor  installé soit sur leur Tablette ou téléphone soit sur un ordinateur de bureau.
                            Le boîtier Convergence alerte par exemple le service de garde de nuit d’une évolution d’un patient sur un ou plusieurs paramètres santé mesurés par l’implant.
                        </p>
                    </div>
                   
                    <div>
                        <img src="../images/centrales.jpg" alt="application ciblée d'un pansement">
                        <h3>Suivi des professionnels dans les milieus à risques</h3>
                        <p>Le boîtier Convergence permet le suivi des données de santé en temps réel des professionnels évoluant dans les milieux à risques telle que leur température ou leur fréquence cardiaque. Ces données sont ensuite transmises au Biotag Cloud, où elles sont analysées et traitées pour fournir des alertes ciblées en cas de potentiel problème de santé. Les personnels de santé peuvent ainsi intervenir rapidement pour prévenir les complications et assurer la sécurité et la santé des professionnels en milieu à risques.</p>
                    </div>
                    <div>
                        <img src="../images/handball.jpg" alt="travail">
                        <h3>Suivi d’un groupe de sportif dans une salle de sport</h3>
                        <p>Le boîtier Convergence permet de collecter en temps réel les données de santé des sportifs équipés d'un BioTagSensor, telles que leur fréquence cardiaque ou leur saturation en oxygène. Ces données sont ensuite transmises au Biotag Cloud, où elles sont analysées et traitées pour fournir des alertes ciblées en cas de potentiel problème de santé. Les coachs et les personnels de santé peuvent ainsi intervenir rapidement pour prévenir les blessures et assurer la sécurité et la santé des sportifs lors de leur entraînement.</p>
                    </div>
                </div>
            </v-col>
        </v-row>
        <foot/>
    </div>
</template>

<script>
import foot from '@/components/Footer.vue'
export default {
    components:{
      foot
    },
}
</script>

<style scoped>
  .img-home {
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    text-align: left;
    position: relative;
  }

  #img-convergence 
  {
    background-image: url(../images/retraités.jpg);
  }

  .img-home::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Ajout du filtre opaque noir */
    background-color: rgba(0, 55, 109, 0.33);
  }

  .text-home {
    position: relative;
    top: 150px;
  }

  .img-convergence {
    margin-top: 40px;
  }

  .img-convergence img {
    width: 100%;
  }

  .div-img-boitier {
    width: 100%;
  }

  .div-img-boitier img {
    border-radius: 20px;
    width: 100%;
  }

  .trais-v {
    width: 80%;
    background-color: var(--violet);
    height: 2px;
  }

  .avantages-perf li {
    color: var(--violet);
    font-family: 'roboto' sans-serif;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .text {
    display: flex;
    justify-content: space-around;
  }

  .text div {
    display: flex;
    align-items: left;
    text-align: left;
    width: 90%;
  }

  .form {
    margin-left: 15%;
    margin-bottom: 50px;
    margin-top: 50px;
    width: 70%;
  }

  .form input {
    width: 55%;
    border: solid 1px var(--violet);
    background-color: transparent;
    height: 35px;
    margin-right: 5%;
  }

  .avantages-perf{
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 20px;
    text-align: center;
    border-radius: 20px;
}

.avantages-perf h3{
    color: var(--violet);
    font-family: 'roboto' sans-serif;
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: 600;
}

.avantages-perf li{
    text-align: justify;
}

  @media (max-width: 1264px) {
    .data-mesurées {
      margin-left: 5%;
      width: 90%;
    }

    .avantages {
      margin-left: 5%;
      width: 90%;
    }

    .applications div {
      width: 45%;
    }
  }

  @media (max-width: 765px) {
    .fonctionnement {
      display: block;
    }

    .fonctionnement div {
      width: 100%;
    }

    .applications {
      display: block;
    }

    .applications div {
      width: 100%;
    }
  }
</style>
